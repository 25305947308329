
//公共库
import { Component, Vue } from "vue-property-decorator";

//本地引入
import Pagination from "@/comp/Pagination/index.vue";
import { userLogout, getUserList } from "@/api/request/bigdata";

//组件
@Component({
  name: "OnlineList",
  components: {
    Pagination,
  },
})

//界面函数
export default class extends Vue {
  //定义变量
  private list: any[] = [];
  private total: number = 0;
  private listLoading: boolean = true; // 列表加载

  //请求数据
  private listQuery: any = {
    //页码参数
    page: 0,
    row: 20,

    //请求参数
    userid: undefined,
  };

  //创建
  created() {
    //获取列表
    this.getList();
  }

  //获取数据
  private async getList() {
    //显示等待
    this.listLoading = true;

    //数据赋值
    if (this.listQuery.userid) this.listQuery.userid = Number(this.listQuery.userid);

    //获取数据
    const { data } = await getUserList(this.listQuery);

    //数据赋值
    this.list = data.summary_list;
    this.total = data.online_total;

    //隐藏等待
    setTimeout(() => {
      this.listLoading = false;
    }, 0.5 * 1000);
  }

  //处理查询
  private handleFilter(): void {
    //数据赋值
    this.listQuery.page = 1;

    //获取列表
    this.getList();
  }

  //处理重置
  private handleReset(): void {
    //数据赋值
    this.listQuery.page = 1;
    this.listQuery.userid = undefined;

    //获取数据
    this.getList();
  }

  //强制下线
  private logoutUser(row: any): void {
    //判断提示
    this.$confirm(`您确定要强制下线 ${row.nickname} ？`, "警告", {
      confirmButtonText: "确定" as string,
      cancelButtonText: "取消" as string,
      type: "warning",
    })
      .then(async () => {
        //请求数据
        await userLogout({ userid: row.id });

        //显示提示
        this.$message({
          type: "success",
          message: "操作成功!",
        });
      })
      .catch((err) => {
        console.error(err);
      });
  }

  //处理输入ID
  private idInputHandler(val: any): void {
    if (val.trim() == "") this.listQuery.userid = undefined;
  }

  //性别筛选
  private sexFormatter(row: any): string {
    //判断返回
    if (row.sex === 0) return "保密";
    if (row.sex === 1) return "男";
    if (row.sex === 2) return "女";

    //返回空
    return "";
  }

  //身份筛选
  private formatBlogger(row: any): string {
    //判断返回
    if (row.isblogger === 0) return "用户";
    if (row.isblogger === 1) return "博主";

    //返回空
    return "";
  }

  //状态筛选
  private statusFormatter(row: any): string {
    //判断返回
    if (row.user_status === "addvideo") return "视频中";
    if (row.user_status === "addvoice") return "音频中";

    //返回空闲
    return "空闲";
  }

  //时间格式化
  private secondsToHHMMSS(time: number): string {
    //数据赋值
    var seconds = time % 60;
    var hours = Math.floor(time / 3600);
    var minutes = Math.floor((time % 3600) / 60);

    //返回结果
    return String(hours).padStart(2, "0") + ":" + String(minutes).padStart(2, "0") + ":" + String(seconds).padStart(2, "0");
  }

  //时间筛选
  private timeFormatter(row: any, column: any): string {
    //判断返回
    if (column.label === "在线时长") return this.secondsToHHMMSS(row.onlinetime);
    if (column.label === "视频时长") return this.secondsToHHMMSS(row.videotime);
    if (column.label === "语音时长") return this.secondsToHHMMSS(row.audiotime);

    //返回空
    return "";
  }
}
